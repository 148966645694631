import React from "react"

import Twitter from "./svg/twitter.svg"
import GitHub from "./svg/github.svg"
import LinkedIn from "./svg/linkedin.svg"

export default function Footer() {

  return <footer className="mw8 center ph2 mt4 mb2 pv3 helvetica">
    
    <div className="bt bw1 b--black cf pv3">

      <div className="w-100 w-two-thirds-ns fl-ns pr4">
        <h5 className="f4 mv2">Picturedigits Ltd</h5>

        <p className="measure-narrow lh-title">
          Company number 12276033 &bull;
          Registered in England and Wales &bull;
          VAT number 336234808
        </p>

        <p>
          Say <a href="mailto:hello@picturedigits.com" className="link black dim underline">
            hello@picturedigits.com
          </a>.
        </p> 

      </div>

      <div className="w-100 w-third-ns fl-ns mt2">
        <a href="https://github.com/picturedigits" className="link black dim underline mr3">
          <img src={GitHub} className="w1" alt="GitHub" />
        </a>
        <a href="https://twitter.com/picturedigits" className="link black dim underline mr3">
          <img src={Twitter} className="w1" alt="Twitter" />
        </a>
        <a href="https://www.linkedin.com/company/picturedigits/" className="link black dim underline">
          <img src={LinkedIn} className="w1" alt="LinkedIn" />
        </a>
      </div>

    </div>

  </footer>
}