import React from "react"
import Helmet from "react-helmet"
import CookieConsent from "react-cookie-consent"

import Picturedigits from "./svg/picturedigits.svg"
import Footer from './footer'

import {useLocation} from "@reach/router"
import {initializeAndTrack} from "gatsby-plugin-gdpr-cookies"

import { Link } from "gatsby"

export default function Layout({children}) {

  const location = useLocation();

  return <>
    <Helmet>
      <title>Picturedigits</title>
      <link rel="icon" type="image/png" href="/favicon.png" />

      <meta name="author" content="Picturedigits" />
      <meta name="description" content="Picturedigits is a geospatial design and technology lab" />
      
      <meta property="og:title" content="Picturedigits" />
      <meta property="og:description" content="Picturedigits is a geospatial design and technology lab" />
      <meta property="og:image" content="https://www.picturedigits.com/images/picturedigits.og.png" />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content="https://www.picturedigits.com/images/picturedigits.og.png" />
      <meta name="twitter:creator" content="@picturedigits" />

    </Helmet>

    <header className="mw8 center ph2 mt4 helvetica cf">
      <div className="bb bw1 b--black">
        <Link to="/" className="link dim">
          <img src={Picturedigits} className="h2" alt="Picturedigits" />
        </Link>
      </div>
    </header>

    <main className="mw8 center ph2 helvetica cf">
      {children}
    </main>

    <Footer />

    <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="gatsby-gdpr-google-analytics"
      className="black"
      containerClasses="helvetica pa2 black bg-white shadow-1"
      style={{backgroundColor: 'white', color: 'black'}}
      buttonStyle={{ backgroundColor: 'white', border: '1px solid black' }}
      buttonClasses="ba bw1 b--black dim white bg-white"
      expires={150}
      onAccept={() => {
        initializeAndTrack(location)
      }}
    >
      This website uses cookies to collect visitor statistics data.
    </CookieConsent>

    <script defer src='https://static.cloudflareinsights.com/beacon.min.js'
      data-cf-beacon='{"token": "025a7411f21c4b94ac5558abd052cd5a"}'></script>

  </>

}